<template>
  <div class="account">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
    <div class="account__header d-flex justify-content-between">
      <div>&nbsp;</div>
      <div
        class="account__header__items d-flex align-items-center justify-content-between"
      >
        <div class="account__logo">
          <!-- <LogoBlack />/ -->
        </div>
        <Country />
      </div>
    </div>
    <div class="account__container">
      <div class="account__left">
        <div class="account__left__bg"></div>
      </div>
      <div class="account__right d-flex flex-column">
        <div class="account__form__container vh-100">
          <div class="account__form__wrapper d-flex flex-column">
            <div class="account__head">
              <h5 class="account__head__title">
                Verify Two Factor Authentication
              </h5>
              <p class="account__head__subtitle">
                Enter token from your Google Authenticator app in the field
                below and click on Verify button to verify and login to your
                dashboard.
              </p>
            </div>
            <div class="account__body">
              <div class="account__form">
                <!-- <div style="display:flex; justify-content: center;">
                  <img :src="generatedQRCode.qrCodeUrl" alt="qr-code" />
                  <qr-code :text="generatedQRCode.qrCodeUrl"></qr-code>
                </div> -->
                <form class="form">
                  <BaseInput
                    label="User Token"
                    id="token1"
                    myRef="token1"
                    autofocus
                    required
                    placeholder="enter token"
                    v-model="twoFaToken"
                    type="text"
                  >
                  </BaseInput>
                  <div
                    class="form__item form__action d-flex align-items-center justify-content-between"
                  >
                    <div style="position: relative; z-index: 3">
                      <button
                        @click="change"
                        class="button form__button form__button--lg"
                        :disabled="!twoFaToken"
                      >
                        {{ process ? "please wait..." : "Verify" }}
                      </button>
                      <img
                        style="
                          position: absolute;
                          left: -5rem;
                          top: -2.5rem;
                          z-index: -1;
                        "
                        src="/assets/img/dotted-bg.svg"
                        alt=""
                        srcset=""
                      />
                    </div>

                    <!-- <div style="position: relative; z-index: 4">
                      <button
                        @click="resendOTP"
                        v-if="isResend"
                        class="button form__button form__button--sm"
                      >
                        {{ confirmSend ? "please wait..." : "resent" }}
                      </button>
                      <label class="account__head__subtitle" v-else>resend in {{ resendTime }} sec</label>
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
            <div class="account__info mt-auto">
              <div class="account__info__container d-flex align-items-center">
                <div class="account__info__icon">
                  <img src="assets/img/help.svg" alt="" srcset="" />
                </div>
                <div class="">
                  <span class="account__text"
                    >Expert support available to you 24 /7.</span
                  >
                  <a href="mailto:support@bookeet.io" class="account__text account__info__link text--capital">
                    Contact Support
                  </a>
                </div>
                <!-- <div class="account__info__logo">
                  <img src="assets/img/logo-black.svg" alt="" srcset="" />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LogoBlack from "@/components/UI/_base-logoBlack.vue";
import Country from "@/components/_base-country-svg.vue";
import BaseInput from "@/components/Inputs/_base-Input.vue";
import { mapState } from "vuex";
import {
  SET_TWO_FACTOR_AUTH,
  GET_TWO_FACTOR_AUTH,
} from "@/core/services/store/types";
import { required } from "vuelidate/lib/validators";
import CPService from "@/core/services/change.password.service";

export default {
  name: "TwoFactorAuth",

  data() {
    return {
      twoFaToken: "",
      resendTime: 0,
      isResend: false,
      process: false,
      intervalCount: null,
      confirmSend: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: `Welcome back`,
      },
    };
  },
  validations: {
    twoFaToken: {
      required,
    },
  },
  methods: {
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    resendCount() {
      this.intervalCount = setInterval(() => {
        this.resendTime++;
        if (this.resendTime === 45) {
          this.isResend = true;
          this.cancelCount();
        }
      }, 1000);
    },
    cancelCount() {
      clearTimeout(this.intervalCount);
      this.intervalCount = null;
    },
    resendOTP(e) {
      e.preventDefault();
      this.confirmSend = true;
      this.$store
        .dispatch(GET_TWO_FACTOR_AUTH)
        .then(() => {
          this.confirmSend = false;
          this.isResend = false;
          this.resendTime = 0;
          this.resendCount();
          this.alertSuccess("OTP sent.");
        })
        .catch(() => {
          this.confirmSend = false;
          this.alertError("OTP failed to send, try again.");
        });
    },
    async change(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.alertError("Token is required");
        return;
      }
      this.process = true;
      const payload = {
        secretCode: CPService.getCPToken().user2faSecretKey
          ? CPService.getCPToken().user2faSecretKey
          : this.generatedQRCode.secretKey,
        userCode: this.twoFaToken,
      };
      this.$store
        .dispatch(SET_TWO_FACTOR_AUTH, payload)
        // go to which page after successfully login
        .then(() => {
          this.process = false;
          CPService.destroyCPToken();
          this.alertSuccess(
            "Your token is verified, you are being redirect to the dashboard"
          );
          setTimeout(() => {
            const nextPage = this.$router.push({ name: "task" });
            this.$router.go(nextPage);
          }, 2000);
        })
        .catch((e) => {
          this.process = false;
          this.alertError(`${e.data.message} verification failed.`);
        });
    },
  },

  computed: {
    ...mapState({
      generatedQRCode: (state) => state.auth.generatedQRCode,
    }),
  },

  mounted() {
    // this.resendCount();
  },

  components: {
    // LogoBlack,
    Country,
    BaseInput,
  },
};
</script>
